<template>
  <div class="row m-0" :class="editing ? 'column-editor':  ''">
    <div class="col-12" v-if="editing">
      <label for="extra-duration">{{ $t('trackAndTrace.column.extraDuration') }}</label>
      <div class="input-group">
        <input type="number" id="extra-duration" class="form-control" v-model="extraDuration">
        <div class="input-group-append">
          <span class="input-group-text extra-days" id="basic-addon2">{{ this.$tc('trackAndTrace.column.days', extraDuration) }}</span>
        </div>
      </div>
    </div>

    <div class="col-12 " v-if="editing">
      <TrackAndTraceActivitiesPool :editing="true" :amountOfColumns="maxAmountOfColumns" />
    </div>

    <template v-if="editing">
      <div class="col-12 activity-columns">

        <div class="activity-column" v-for="column in getColumnsToShow" :key="'column-wrapper-' + column">
          <TrackAndTraceColumn
              :jobs="getJobsOfColumn(column)"
              @labelChanged="setColumnLabel($event, column)"
              @subActivityChanged="emitColumnChange($event, column)"
              @listChanged="setColumnActivities($event, column)"
              :activityToShowDate="getLastFinishedActivity"
              :currentActivity="getCurrentActivity"
              :editing="editing" :columnIndex="column"
          />
        </div>

      </div>
    </template>
    <template v-else>
      <div :class="getColumnWidth[column]" class="col-12 activity-column" v-for="column in getColumnsToShow" :key="'column-wrapper-' + column">
          <TrackAndTraceColumn
              :jobs="getJobsOfColumn(column)"
              @labelChanged="setColumnLabel($event, column)"
              @listChanged="setColumnActivities($event, column)"
              @subActivityChanged="emitColumnChange($event, column)"
              :activityToShowDate="getLastFinishedActivity"
              :currentActivity="getCurrentActivity"
              :editing="editing" :columnIndex="column"
          />
        </div>

    </template>
  </div>
</template>

<script>
import TrackAndTraceColumn from "../../components/trackAndTrace/TrackAndTraceColumn.vue";
import TrackAndTraceActivitiesPool from "../../components/trackAndTrace/TrackAndTraceActivitiesPool.vue";
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTraceColumns",
  components: {TrackAndTraceActivitiesPool, TrackAndTraceColumn},
  props: ['editing', 'jobs'],
  data() {
    return {
      maxAmountOfColumns: 4,
      extraDuration: 0,
      activitiesColumn0: {label: null, activities: [] },
      activitiesColumn1: {label: null, activities: [] },
      activitiesColumn2: {label: null, activities: [] },
      activitiesColumn3: {label: null, activities: [] }
    }
  },
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    getColumnWidth() {
      let columnWidths = [ 'col-lg',  'col-lg', 'col-lg', 'col-lg' ];
      const columnsToShow = this.getColumnsToShow;
      for(let columnIndex = 0; columnIndex < columnsToShow.length; columnIndex++) {
        const column = columnsToShow[columnIndex];
        const lastColumn = columnIndex === columnsToShow.length-1;

        if (lastColumn) {
          columnWidths[column] = 'col-lg'
        } else {
          const amountOfActivitiesInColumn = this[`activitiesColumn${column}`].activities.length;
          if (amountOfActivitiesInColumn >= 4) {
            columnWidths[column] = 'col-lg-4'
          } else {
            columnWidths[column] = 'col-lg'
          }
        }
      }
      return columnWidths;
    },
    getColumnsToShow() {
      if (this.editing) {
        let columnsToShow = [];
        for(let i = 0; i<this.maxAmountOfColumns;i++) {
          columnsToShow.push(i);
        }
        return columnsToShow;
      } else {
        let columnsToShow = [];
        for(let i = 0; i< this.maxAmountOfColumns;i++) {
          if (this.getTrackAndTraceConfig[`activitiesColumn${i}`].activities.length) {
            columnsToShow.push(i);
          }
        }
        return columnsToShow;
      }
    },
    getLastFinishedActivity() {
      let lastFinishedActivity = null;
      for(let i = 0; i < this.maxAmountOfColumns; i++) {
        for(let a = 0; a < this[`activitiesColumn${i}`].activities.length; a++) {
          const activity = this[`activitiesColumn${i}`].activities[a];
          const jobWithActivity = _.find(this.jobs, { 'activity': activity.value });
          if (jobWithActivity && jobWithActivity.status === 'finished'){
            lastFinishedActivity = activity.value;
          }
        }
      }
      return lastFinishedActivity;
    },
    getCurrentActivity() {
      let currentActivity = null;
      let previousActivityFinished = false;
      for(let i = 0; i < this.maxAmountOfColumns; i++) {
        for(let a = 0; a < this[`activitiesColumn${i}`].activities.length; a++) {
          const activity = this[`activitiesColumn${i}`].activities[a];
          const jobWithActivity = _.find(this.jobs, { 'activity': activity.value });
          if (jobWithActivity && jobWithActivity.status === 'finished'){
            previousActivityFinished = true;
          } else if (previousActivityFinished) {
            previousActivityFinished = false;
            currentActivity = activity.value;
          }
        }
      }
      return currentActivity;
    }
  },
  methods: {
    emitColumnChange(event, column) {
      this.$emit('columnChanged', { columnIndex: column, data: event })
    },
    getJobsOfColumn(columnIndex) {
      return _.filter(this.jobs, job => {
        if (this.getTrackAndTraceConfig && this.getTrackAndTraceConfig[`activitiesColumn${columnIndex}`]) {
          const matchingActivitiesWithJob = _.find(this.getTrackAndTraceConfig[`activitiesColumn${columnIndex}`].activities, { 'value': job.activity });
          if (matchingActivitiesWithJob) {
            return true;
          } else {
            return false;
          }
        }
      })
    },
    setColumnActivities(data, columnIndex) {
      this[`activitiesColumn${columnIndex}`].activities = data;
    },
    setColumnLabel(data, columnIndex) {
      this[`activitiesColumn${columnIndex}`].label = data;
    }
  },
  created() {
    for(let i = 0; i < this.maxAmountOfColumns; i++ ){
      if (this.getTrackAndTraceConfig[`activitiesColumn${i}`]) {
        this[`activitiesColumn${i}`] = this.getTrackAndTraceConfig[`activitiesColumn${i}`];
      }
    }
    this.extraDuration = this.getTrackAndTraceConfig.extraDuration ? this.getTrackAndTraceConfig.extraDuration : this.extraDuration;
  },
  watch: {
    'activitiesColumn0': {
      deep: true,
      handler(newValue) {
        this.$emit('columnChanged', { columnIndex: 0, data: newValue })
      }
    },
    'activitiesColumn1': {
      deep: true,
      handler(newValue) {
        this.$emit('columnChanged', { columnIndex: 1, data: newValue })
      }
    },
    'activitiesColumn2': {
      deep: true,
      handler(newValue) {
        this.$emit('columnChanged', { columnIndex: 2, data: newValue })
      }
    },
    'activitiesColumn 4': {
      deep: true,
      handler(newValue) {
        this.$emit('columnChanged', { columnIndex: 3, data: newValue })
      }
    },
    'extraDuration': {
      handler(newValue) {
        this.$emit('extraDurationChanged', newValue);
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

.column-editor {
  border: 1px solid $cultured;
  padding: 2rem;
  margin: 1rem !important;
}

.remaining-time-col {
  margin-bottom: 1rem;
}

.activity-column {
  padding-bottom: 2rem;
}

@media only screen and (max-width: 900px) {
  .activity-column {
    padding: 8px !important;
  }

  .activity-column:last-child {
    padding-bottom: 2rem !important;
  }
}


.activity-columns {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

#extra-duration {
  margin-bottom: 12px;
}

.extra-days {
  margin-bottom: 12px;
  padding: 4px 12px;
  width: 100px;
  background-color: $softGrey;
  color: inherit;
  height: 49px;
}
</style>