<template>
  <div class="track-and-trace-banner-wrapper">
    <div class="page-title-edit" v-if="editing">
      <label for="title-planning">{{ $t('trackAndTrace.banner.pageTitle') }}</label>
      <input type="text" class="track-and-trace-banner-title-input form-control" :placeholder="$t('trackAndTrace.banner.placeholders.pageTitle')" v-model="bannerText">

        <label for="title-order-not-found" id="title-order-not-found">{{ $t('trackAndTrace.banner.orderNotFound') }}</label>
        <input type="text" class="track-and-trace-banner-title-input form-control" :placeholder="$t('trackAndTrace.banner.placeholders.orderNotFound')" v-model="orderNotFoundText">

    </div>

    <div class="track-and-trace-banner">
      <div class="track-and-trace-banner-title" v-if="!editing">
        {{ getTrackAndTraceConfig.bannerText }}
      </div>
      <div v-if="!editing" class="logo-img" :style="{'background-image': `url('${downloadUrl ? downloadUrl : require('../../assets/banner-background-default.png')}')`}">
        <div class="tack-and-trace-banner-filter"></div>
      </div>

      <div v-else class="banner-placeholder">
        <div class="banner-drag-drop-container">
          <DragAndDropContainer ref="dragAndDropContainer"
                                :numberOfFilesAllowed="1"
                                :allowedFileSize="'5MB'"
                                :showFailedToast="true"
                                :deletable="false"
                                :showSuccessToast="false"
                                :uploadedFiles="getBannerFile"
                                :path="getTrackAndTraceFilePath"
                                :hide-manual-button="true"
                                :hide-library="true"
                                :accepted-file-types="'image/*'"
                                :help-text="$t('trackAndTrace.banner.placeholders.dragAndDropHelpText')"
          ></DragAndDropContainer>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DragAndDropContainer from "@/components/filemanager/DragAndDropContainer";
import firebase from "firebase";

export default {
  name: "TackAndTraceBanner",
  props: [ 'editing' ],
  components: {DragAndDropContainer},
  data() {
    return {
      downloadUrl: null,
      bannerText: '',
      orderNotFoundText: ''
    }
  },
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    getTrackAndTraceFilePath() {
      const companyId = this.getTrackAndTraceConfig.companyId;
      const configId = this.getTrackAndTraceConfig.id;
      return `trackAndTrace/${companyId}/${configId}`;
    },
    getBannerFile() {
      if (!this.getTrackAndTraceConfig || !this.getTrackAndTraceConfig.bannerFileName) {
        return [];
      } else {
        return [{
          bucket: 'performance-management-tool.appspot.com',
          color: null,
          fullPath: `trackAndTrace/${this.getTrackAndTraceConfig.companyId}/${this.getTrackAndTraceConfig.id}/${this.getTrackAndTraceConfig.bannerFileName}`,
          name: this.getTrackAndTraceConfig.bannerFileName,
          type: 'image/*'
        }]
      }
    },
  },
  methods: {
    async getDownloadURLFromBucket() {
      const bannerFile = this.getBannerFile;
      if (bannerFile.length) {
        const path = bannerFile[0].fullPath;
        const fileRef = firebase
            .storage()
            .ref(`${path}`);
        this.downloadUrl = await fileRef.getDownloadURL();
      }
    }
  },
  created() {
    this.bannerText = this.getTrackAndTraceConfig.bannerText;
    this.orderNotFoundText = this.getTrackAndTraceConfig.orderNotFoundText ? this.getTrackAndTraceConfig.orderNotFoundText : this.orderNotFoundText;
    this.getDownloadURLFromBucket();
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

$bannerHeight: 211px;

.track-and-trace-banner {
  height: $bannerHeight;
  background: $walterWhite;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logo-img {
  width: 100%;
  height: $bannerHeight;
  background-size: cover;
}

.page-title-edit {
  padding: 12px 48px;
  background: $walterWhite;
}

.banner-drag-drop-container {
  width: 100%;
}


@media only screen and (max-width: 900px) {
  .track-and-trace-banner-title {
    font-size: 1.5rem !important;

  }
}

.track-and-trace-banner-title {
  position: relative;
  top: 40px;
  height: 0px;
  padding-left: 48px;
  top: 67px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 120%;
  color: $walterWhite;
  z-index: 99;
}

#title-order-not-found {
  margin-top: 1rem;
}



.tack-and-trace-banner-filter {
  height: 100%;
  position: relative;
  background: linear-gradient(180deg, rgba(43, 119, 166, 0.4) 0.52%, rgba(0, 175, 164, 0.4) 75%, rgba(17, 104, 102, 0.4) 99.98%, rgba(0, 175, 164, 0.4) 99.99%);
}

/deep/ .banner-drag-drop-container > .container-fluid > .drag-drop-container > .first-column  {
  padding-left: 0px;
  padding-right: 0px;
}



/deep/ .file-preview {
  height: calc(#{$bannerHeight} - 2px - 2px - 2px - 2px - 2px) !important; // The 2px minusses are the padding/margin of the plugin

  .file-name {
    font-size: 0.875rem;
  }
  .file-ext, .file-size {
    font-size: 0.75rem;
  }
  .image-dimension, .file-ext, .file-size {
    bottom: 40px;
  }

  span {
    line-height: unset !important;

    svg {
      display: none;
    }

    .help-text {
      left: 0px !important;
    }
  }
}
</style>