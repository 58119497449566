<template>
  <div :editing="editing" class="track-and-trace-header-editor row">
    <template v-if="editing">
      <div class="col-12 ">
        <div class="logo-placeholder">
          <label for="">{{ $t('trackAndTrace.header.logo') }}</label>
          <div class="logo-drag-drop-container">
            <DragAndDropContainer ref="dragAndDropContainer"
                                  :numberOfFilesAllowed="1"
                                  :allowedFileSize="'5MB'"
                                  :showFailedToast="true"
                                  :deletable="false"
                                  :showSuccessToast="false"
                                  :uploadedFiles="getLogoFile"
                                  :path="getTrackAndTraceFilePath"
                                  :hide-manual-button="true"
                                  :hide-library="true"
                                  :accepted-file-types="'image/*'"
                                  :help-text="$t('trackAndTrace.header.placeholders.dagAndDropHelpText')"
            ></DragAndDropContainer>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="page-title-edit" v-if="editing">
          <label for="title-planning">{{ $t('trackAndTrace.header.secretKey') }}</label>
          <input type="text" class="track-and-trace-banner-title-input form-control"
                 :placeholder="$t('trackAndTrace.header.placeholders.secretKey')" v-model="secretKeyLabel">
        </div>
      </div>
    </template>

    <div class="track-and-trace-logo" v-if="!editing">
      <img :src="downloadUrl" class="logo-img" v-if="getLogoFile.length"/>
      <img src="@/assets/Logo-2022-Motivate.png" class="logo-img" v-else-if="!getLogoFile.length"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DragAndDropContainer from "@/components/filemanager/DragAndDropContainer";
import firebase from "firebase";

export default {
  name: "TackAndTraceHeader",
  props: ['editing'],
  components: {DragAndDropContainer},
  data() {
    return {
      downloadUrl: null,
      secretKeyLabel: ''
    }
  },
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    getLogoFile() {
      if (!this.getTrackAndTraceConfig || !this.getTrackAndTraceConfig.logoFileName) {
        return [];
      } else {
        return [{
          bucket: 'performance-management-tool.appspot.com',
          color: null,
          fullPath: `trackAndTrace/${this.getTrackAndTraceConfig.companyId}/${this.getTrackAndTraceConfig.id}/${this.getTrackAndTraceConfig.logoFileName}`,
          name: this.getTrackAndTraceConfig.logoFileName,
          type: 'image/*'
        }]
      }
    },
    getTrackAndTraceFilePath() {
      const companyId = this.getTrackAndTraceConfig.companyId;
      const configId = this.getTrackAndTraceConfig.id;
      return `trackAndTrace/${companyId}/${configId}`;
    },
  },
  methods: {
    async getDownloadURLFromBucket() {
      const logoFile = this.getLogoFile;
      if (logoFile.length) {
        const path = logoFile[0].fullPath;
        const fileRef = firebase
            .storage()
            .ref(`${path}`);
        this.downloadUrl = await fileRef.getDownloadURL();
      }
    }
  },
  created() {
    this.getDownloadURLFromBucket();
    this.secretKeyLabel = this.getTrackAndTraceConfig.secretKeyLabel ?  this.getTrackAndTraceConfig.secretKeyLabel : this.secretKeyLabel;
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

$headerHeight: 48px;

.language-selector {
  display: inline-block;
  position: relative;
  right: 0px;
}

.track-and-trace-header-editor {
  background: $walterWhite;
  padding-left: 34px;
  padding-right: 34px;
  margin: 0px;
}


.track-and-trace-header {
  height: $headerHeight;
  background: $walterWhite;
  padding-left: 48px;
  padding-right: 48px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, auto));
  align-items: center;

}

.track-and-trace-header[editing=true] {
  height: calc(#{$headerHeight} * 2);
}

.logo-img {
  height: 39px;
  margin-top: calc((#{$headerHeight} - 39px) / 2 - 0.5rem);
}

.logo-drag-drop-container {
  width: 400px;
}

/deep/ .file-preview {
  height: calc(#{$headerHeight} - 2px - 2px - 2px - 2px - 2px) !important; // The 2px minusses are the padding/margin of the plugin

  .file-name {
    font-size: 0.875rem;
  }

  .file-ext, .file-size {
    font-size: 0.75rem;
  }


  span {
    line-height: unset !important;

    svg {
      display: none;
    }

    .help-text {
      left: 0px !important;
    }
  }
}

.logo-drag-drop-container {
  margin-bottom: 12px;
}

.language-selector {
  width: 100%;
}
</style>