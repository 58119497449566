<template>
  <div class="fileBrowser">
    <div class="header row">
      <h5 class="col-6"> {{ $t('fileBrowser.title') }}</h5>
      <div class="sort col-2">
        <div class="sort-button noSelect" @click="setAbcSort" type="abc" :active="abcSort !== 0">
          <div class="sort-button-left-ico">
            <i v-if="abcSort === -1" class="fa fa-arrow-up"></i>
            <i v-else class="fa fa-arrow-down"></i>
          </div>
          <div class="sort-button-right-top-ico">
            {{  $t('fileBrowser.sortABCMin') }}
          </div>
          <div class="sort-button-right-bottom-ico">
            {{  $t('fileBrowser.sortABCMax') }}
          </div>
        </div>
        <div class="sort-button noSelect" @click="setDateSort" type="date" :active="dateSort !== 0">
          <div class="sort-button-left-ico">
            <i v-if="dateSort === -1" class="fa fa-arrow-up"></i>
            <i v-else class="fa fa-arrow-down"></i>
          </div>
          <div class="sort-button-right-top-ico">
            {{  $t('fileBrowser.sortDateMin') }}
          </div>
          <div class="sort-button-right-bottom-ico">
            {{  $t('fileBrowser.sortDateMax') }}
          </div>
        </div>
      </div>
      <div class="search col-4">
        <div class="form-group">
          <div class="form-group">
            <input name="fileNameSearch"
                   class="form-control"
                   v-model="fileNameSearch"
                   id="fileNameSearch"
                   :placeholder="$t('fileBrowser.search')"
                   type="text"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="last-files"  v-if="getLastUploadedFiles(8).length">
      <div class="toggle-wrapper">
        <div @click="collapsed = !collapsed; enableAnimatedCollapse = true" :collapsed="collapsed" class="toggle-button" type="button" data-toggle="collapse" data-target="#collapseFiles"
             aria-expanded="true" aria-controls="collapseFiles">
        </div>
      </div>

      <div class="collapse show" id="collapseFiles">
        <div class="last-uploaded-files">
          <h6 class="last-uploaded-files-title">{{ $t('fileBrowser.lastUploadedFiles')}}</h6>
          <div class="files">
            <FileTile v-bind:key="index" :file="file" @openFile="openFile($event)"
                      @showHistory="showHistory($event.$event, $event.file); enableAnimatedCollapse = false;"
                      :class="enableAnimatedCollapse ? (collapsed ? 'file-tile-hidden' : 'file-tile-visible') : ''"
                      v-for="(file, index) in getLastUploadedFiles(8)"></FileTile>
          </div>
        </div>
      </div>


    </div>

    <div class="files" v-if="getFilesFiltered.length">
      <FileTile v-bind:key="index" :file="file" @openFile="openFile($event)"
                @showHistory="showHistory($event.$event, $event.file)"
                v-for="(file, index) in getFilesFiltered"></FileTile>
    </div>

    <div class="noFiles" v-else>
      {{ $t('fileBrowser.noFilesFound') }}
    </div>
    <div class="footer">
      <div class="col-12 text-right">
        <button data-toggle="modal"
                data-target="#fileBrowserModal"
                class="btn btn-cancel">
          {{ $t('ui.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import firebase from "firebase";
import mimetypes from 'font-awesome-filetypes/lib/mimetypes'
import FileTile from "@/components/filemanager/FileTile";

export default {
  name: "FileBrowser",
  components: {FileTile},
  props: ['path'],
  data() {
    return {
      files: [],
      fileNameSearch: '',
      abcSort: 0,
      dateSort: 0,
      collapsed: false,
      enableAnimatedCollapse: false
    }
  },
  computed: {
    ...mapGetters("users", ["getClaims", "getMe"]),
    getFilesFiltered() {
      let sortKeys = [];
      let sortOrder = [];

      if (this.abcSort !== 0) {
        sortKeys.push('name');
        sortOrder.push(this.abcSort === -1 ? 'desc' : 'asc');
      }

      if (this.dateSort !== 0) {
        sortKeys.push('updated');
        sortOrder.push(this.dateSort === -1 ? 'desc' : 'asc');
      }

      return _.orderBy(_.filter(this.files, file => {
        return !this.fileNameSearch || file.name.toLowerCase().indexOf(this.fileNameSearch.toLowerCase()) > -1;
      }), sortKeys, sortOrder);
    }
  },
  methods: {
    getLastUploadedFiles(amount = 0) {
      return _.take(_.orderBy(this.files, ['updated'], ['desc']), amount);
    },
    setAbcSort() {
      if (this.abcSort === 0) {
        this.abcSort = 1;
        this.dateSort = 0;
      } else if (this.abcSort === 1) {
        this.abcSort = -1;
        this.dateSort = 0;
      } else {
        this.abcSort = 0;
      }
    },
    setDateSort() {
      if (this.dateSort === 0) {
        this.dateSort = 1;
        this.abcSort = 0;
      } else if (this.dateSort === 1) {
        this.dateSort = -1;
        this.abcSort = 0;
      } else {
        this.dateSort = 0;
      }
    },
    async getFilesInPath() {
      const storageRef = firebase
          .storage()
          .ref(this.path);
      const storageListings = await storageRef.listAll();
      for (let i = 0; i < storageListings.items.length; i++) {
        const metadata = await firebase.storage().ref(storageListings.items[i].fullPath).getMetadata();
        this.files.push(metadata);
      }
    },
    openFile(file) {
      $('#fileBrowserModal').modal('hide');
      this.$emit('openFile', {file})
    },
    async showHistory(event, file) {
      event.stopPropagation();
      this.$emit('openFileVersionSelector', file);
    },
  }
  ,
  created() {
    this.getFilesInPath();
  }
}
</script>

<style lang="scss" scoped>

.file-select {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.file-select {
  width: 100%;

  & > input[type="file"] {
    display: none;
  }

  .btn,
  span {
    display: block;
    text-align: left;
    overflow: auto;
    word-break: break-word;
    text-overflow: ellipsis;
  }

  .file-select-icon {
    margin-right: 10px;
    text-align: left;
  }
}

.files {
  overflow-y: auto;
  padding: 1rem;
}

.files {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, auto));
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  width: 100%;
  justify-items: center;
}

.fileBrowser {
  padding: 1rem;
}

.footer {
  margin-top: 1rem;
}

.header > h5 {
  display: grid;
  align-self: center;
}

.header {
  margin-left: 1rem;
  margin-right: 1rem;
}

.sort-button[active=true] {
  color: #00C9FF
}

.sort-button[active=false] {
  color: inherit;
}

.sort-button {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.sort-button[type='abc'] {
  width: 3rem;
}

.sort-button[type='date'] {
  width: 5rem;
}

.sort-button-left-ico {
  grid-area: 1 / 1 / 3 / 2;
  font-weight: bold;
}

.sort-button-right-top-ico {
  grid-area: 1 / 2 / 2 / 3;
  font-size: 1.1rem;
  font-weight: bold;
}

.sort-button-right-bottom-ico {
  font-size: 1.1rem;
  font-weight: bold;
  grid-area: 2 / 2 / 3 / 3;
}

.sort {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.form-group {
  margin-bottom: 0px;
}

.noFiles {
  margin-left: 2.4rem;
}

.last-uploaded-files {
  border: 1px solid gainsboro;
}

.toggle-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.toggle-button {
  cursor: pointer;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  border: 1px solid;
  display: inline-block;
  padding: 1rem;
  border-radius: 24px;
  width: 3rem;
  height: 3rem;
  line-height: 1rem;
  font-weight: bold;
  background: white;
  position: fixed;
  margin-top: -15px;
  margin-left: -1.5rem;
}

.toggle-button::after {
  content: '\f106';
  font-family: "Font Awesome 5 Pro";
}

.toggle-button[collapsed="true"]::after {
  content: '\f107';
  font-family: "Font Awesome 5 Pro";
}

.last-uploaded-files-title {
  margin-left: 3rem;
  padding-top: 3rem;
  font-weight: bold;
}

#collapseFiles {
  background: #f8f8f8;
}

.file-tile-visible {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 100ms both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 100ms both;
}

.file-tile-hidden {
  -webkit-animation: scale-out-center 0.5s ease-in-out both;
  animation: scale-out-center 0.5s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-17 15:38:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
</style>