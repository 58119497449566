<template>
  <div class="order-information-wrapper">
    <div class="row m-0 order-information">
      <div class=" col-12 col-md-6">
        <template v-if="!editing">
          <div class="order-label">{{ $t('trackAndTrace.information.order') }}:</div>
          <div v-if="orderStatus" class="order-value">{{ getOrderNumber }}</div>
        </template>
        <div v-else class="order-information-edit-order-number">
          <label for="order-number-key">{{ $t('trackAndTrace.information.orderKey') }}</label>
          <select name="" class=form-control v-model="orderNumberKey" id="order-number-key">
            <option v-for="key in getPossibleProductKeys" :value="key" :key="key">{{ key }}</option>
          </select>
        </div>
      </div>
      <div class="  col-12 col-md-6" >
        <template v-if="!editing && getTrackAndTraceConfig.informationText">
          <div class="info-label">{{ $t('trackAndTrace.information.moreInfo') }}:</div>
          <div class="info-value">
            <template v-if="!getTrackAndTraceConfig.informationLink">
              <div class="not-linked-text">{{ getTrackAndTraceConfig.informationText }}</div>
            </template>
            <template v-else>
              <a :href="getTrackAndTraceConfig.informationLink" target="_blank">{{ getTrackAndTraceConfig.informationText }}</a>
            </template>
          </div>
        </template>
        <div v-else>
          <div class="order-information-edit-extra-info">
            <label for="information-text">{{ $t('trackAndTrace.information.moreInfoEdit') }}</label>
            <input type="text" id="information-text" class="form-control" v-model="informationText" >
            <label for="information-link">{{ $t('trackAndTrace.information.moreInfoLink')  }}</label>
            <input type="text" id="information-link" class="form-control" v-model="informationLink" :placeholder="'https://www.google.nl'">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTraceInformation",
  props: ['editing', 'orderStatus'],
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    ...mapGetters("jobs", ["getJobs"]),
    ...mapGetters("users", ["getClaims", "getMe"]),
    getPossibleProductKeys() {
      const uniqueKeys = _.uniq(_.flattenDeep(_.map(this.getJobs, job => { return _.keys(job) })));
      return _.filter(uniqueKeys, key => [ 'created_at', 'created_by', 'updated_at', 'updated_by', 'id', 'companyId'].indexOf(key) === -1)
    },
    getOrderNumber() {
      return _.first(_.first(this.orderStatus))[this.getTrackAndTraceConfig.orderNumberKey];
    }
  },
  data() {
    return {
      informationLink: null,
      informationText: null,
      orderNumberKey: 'orderNumber'
    }
  },
  created() {
    this.informationText = this.getTrackAndTraceConfig.informationText;
    this.informationLink = this.getTrackAndTraceConfig.informationLink;
    this.orderNumberKey = this.getTrackAndTraceConfig.orderNumberKey ? this.getTrackAndTraceConfig.orderNumberKey : this.orderNumberKey;

    if (this.editing){
      let where = [
        ['companyId', '==', this.getClaims.companyId],
        ['plannedDate', '>=', moment().subtract(5, 'day').toDate()]
      ];
      this.$store.dispatch('jobs/fetchAndAdd', {where});
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

$informationHeight: 68px;

@media only screen and (max-width: 900px) {
  .order-information-wrapper {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .order-information {
    padding:8px !important;
  }
}


.order-information-wrapper {
  margin-left: 48px;
  margin-right: 48px;
  position: relative;
  top: calc(0px - #{$informationHeight} / 2);
  z-index: 99;
}

.order-information {
  min-height: $informationHeight;
  background: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 8px;
  padding: 12px 32px;
}


.information-link-input {
  width: 300px;
}

#dropdownMenuLink {
  cursor: pointer;
}

.order-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $besWelBlack;
  text-align: left;
}

.order-value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.25px;
  color: $besWelBlack;
  text-align: left;

  a {
    color: $besWelBlack;
  }
}



.info-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $besWelBlack;
  text-align: right;
}

@media (max-width: 768px) {
  .info-label, .info-value {
    text-align: left !important;
  }
}

#information-text {
  margin-bottom: 12px;
}

.info-value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.25px;
  color: $besWelBlack;
  text-align: right;

  a {
    color: $besWelBlack;
    text-decoration: underline;
  }
}

</style>