<template>
  <div class="track-and-trace-wrapper" v-if="getTrackAndTraceConfig.language !== undefined">
    <TrackAndTraceHeader :editing="editing" ref="trackAndTraceHeader" />
    <TrackAndTraceBanner :editing="editing" ref="trackAndTraceBanner"/>
    <TrackAndTraceInformation :orderStatus="orderStatus"  :editing="editing" ref="trackAndTraceInformation" />
    <TrackAndTraceContent :orderStatus="orderStatus" :editing="editing" ref="trackAndTraceContent" />
  </div>
</template>

<script>
import TrackAndTraceHeader from './TrackAndTraceHeader.vue';
import TrackAndTraceBanner from './TrackAndTraceBanner.vue';
import TrackAndTraceInformation from "./TrackAndTraceInformation.vue";
import TrackAndTraceContent from "./TrackAndTraceContent.vue";
import MotivateLogoWrapper from "./MotivateLogoWrapper.vue";
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTrace",
  props: ['editing', 'orderStatus'],
  components: {
    MotivateLogoWrapper,
    TrackAndTraceContent, TrackAndTraceInformation, TrackAndTraceHeader, TrackAndTraceBanner},
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    ...mapGetters(['getLocale'])
  },
  methods: {
    async save() {
      const headerResult = await this.$refs.trackAndTraceHeader.$refs.dragAndDropContainer.uploadFiles(false, this.getTrackAndTraceConfig.id);
      const secretKeyLabel = this.$refs.trackAndTraceHeader.secretKeyLabel;

      const bannerResult = await this.$refs.trackAndTraceBanner.$refs.dragAndDropContainer.uploadFiles(false, this.getTrackAndTraceConfig.id);
      const bannerText = this.$refs.trackAndTraceBanner.bannerText;
      const orderNotFoundText = this.$refs.trackAndTraceBanner.orderNotFoundText;
      const orderNumberKey = this.$refs.trackAndTraceInformation.orderNumberKey;
      const informationLink = this.$refs.trackAndTraceInformation.informationLink;
      const informationText = this.$refs.trackAndTraceInformation.informationText;


      const customPropertyKey0 = this.$refs.trackAndTraceContent.customPropertyKey0;
      const customPropertyLabel0 = this.$refs.trackAndTraceContent.customPropertyLabel0;
      const customPropertyKey1 = this.$refs.trackAndTraceContent.customPropertyKey1;
      const customPropertyLabel1 = this.$refs.trackAndTraceContent.customPropertyLabel1;
      const customPropertyKey2 = this.$refs.trackAndTraceContent.customPropertyKey2;
      const customPropertyLabel2 = this.$refs.trackAndTraceContent.customPropertyLabel2;

      const activitiesColumn0 = this.$refs.trackAndTraceContent.activitiesColumn0;
      const activitiesColumn1 = this.$refs.trackAndTraceContent.activitiesColumn1;
      const activitiesColumn2 = this.$refs.trackAndTraceContent.activitiesColumn2;
      const activitiesColumn3 = this.$refs.trackAndTraceContent.activitiesColumn3;

      const extraDuration = this.$refs.trackAndTraceContent.extraDuration;

      const language = this.getLocale;

      return {headerResult, bannerResult, bannerText, orderNumberKey, language, orderNotFoundText,
        informationLink, informationText, customPropertyKey0, customPropertyLabel0,
        customPropertyKey1, customPropertyLabel1, customPropertyKey2, customPropertyLabel2, activitiesColumn0, secretKeyLabel,
        activitiesColumn1, activitiesColumn2, activitiesColumn3, extraDuration };
    }
  },
  created() {
    if (!this.editing) {
      // Make track and trace bigger
      $("html").css("font-size", "20px");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

.track-and-trace-wrapper {
  background: $ultraLightGrey;
  min-height: 100%;
}


</style>