<template>
  <div class="container-fluid">
    <div class="drag-drop-container row">
      <div class="first-column" :class="!hideManualButton && !hideLibrary ? 'col-md-6' : (hideLibrary !== hideManualButton ? 'col-md-9' : 'col-md-12')">
        <VueFileAgent
            ref="vueFileAgent"
            :theme="'list'"
            :multiple="numberOfFilesAllowed > 1"
            :deletable="!!deletable"
            :meta="true"
            :accept="acceptedFileTypes ? acceptedFileTypes : 'image/*,.pdf,.PDF'"
            :maxSize="allowedFileSize"
            :maxFiles="numberOfFilesAllowed "
            :helpText="helpText ? helpText : $t('dragAndDropContainer.helpText')"
            :errorText="{
            type: $t('dragAndDropContainer.errorText.type'),
            size: $t('dragAndDropContainer.errorText.size', { allowedFileSize }),
          }"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            v-model="fileRecords"
        ></VueFileAgent>
      </div>
      <div v-if="!hideLibrary" class="col-md-3">
        <button class="btn btn-primary file-button"
                role="button"
                data-toggle="modal"
                data-target="#fileBrowserModal">
          {{ $t('dragAndDropContainer.browseLibrary') }}
        </button>
      </div>
      <div v-if="!hideManualButton" class="col-md-3">
        <button class="btn btn-primary file-button"
                role="button"
                @click="uploadLocalFile()">
          {{ $t('dragAndDropContainer.localFile') }}
        </button>
      </div>

      <div
          class="modal fade"
          id="fileBrowserModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
      >
        <div style="max-width: 1000px" class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <FileBrowser @openFileVersionSelector="openFileVersionSelector" @openFile="openFile" ref="fileBrowser"
                         :path="path"></FileBrowser>
          </div>
        </div>
      </div>


      <div
          class="modal fade"
          id="fileVersionSelectorModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <FileVersionSelector ref="fileVersionSelector" @fileSelected="openFile"></FileVersionSelector>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import firebase from "firebase";
import {mapGetters} from "vuex";
import FileBrowser from "@/components/filemanager/FileBrowser";
import axios from "axios";
import FileVersionSelector from "@/components/filemanager/FileVersionSelector";

export default {
  name: "DragAndDropContainer",
  components: {FileVersionSelector, FileBrowser},
  props: ['path', 'uploadedFiles', 'showSuccessToast', 'showFailedToast', 'numberOfFilesAllowed', 'allowedFileSize', 'hideLibrary', 'hideManualButton', 'helpText', 'acceptedFileTypes', 'deletable'],
  data: function () {
    return {
      fileRecords: [], // contains all files that can be uploaded
      fileRecordsForUpload: [], // contains all files: including those that aren't allowed to upload
    };
  },
  computed: {
    ...mapGetters("users", ["getClaims", "getMe"])
  },
  methods: {
    openFileVersionSelector(file) {
      // Open the file version modal and close the file browser modal
      $('#fileBrowserModal').modal('hide');
      $('#fileVersionSelectorModal').modal('show');

      // On hidden: re-open the file browsermodal
      $('#fileVersionSelectorModal').on('hidden.bs.modal', function (e) {
        // Check if a file has been selected: if not open the file browser again
        if (!$('#fileVersionSelectorModal').attr('fileSelected')) {
          $('#fileBrowserModal').modal('show');
        }
        $('#fileVersionSelectorModal').attr('fileSelected', null)
        $('#fileVersionSelectorModal').off('hidden.bs.modal');
      });

      // Get currently selected path
      const currentSelectedPath = _.find(this.uploadedFiles, uploadedFile => {
        // If uploaded file and selected file have the same name it's the last version
        if (uploadedFile.name === file.name) {
          return true;
        } else {
          // If it's not the same: we extract the file name by removing the file version and check if it's the same
          const match = uploadedFile.name.match(/(\d+.\d+.\d+_)(.*)/);
          return match.length && match[2] === file.name;
        }
      })

      this.$refs.fileVersionSelector.getFileVersions(file, currentSelectedPath);

    },
    uploadLocalFile() {
      $('.file-input').click();
    },
    openFile(event) {
      this.getPreUploadedFilePreviews([{fullPath: event.file.fullPath}]);
    },
    async filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = validFileRecords;
    },
    async showFileExistsPrompt(fileName) {
      return this.$swal({
        title: this.$root.$t('dragAndDropContainer.fileAlreadyExists.title'),
        html: this.$root.$t('dragAndDropContainer.fileAlreadyExists.message', {fileName}),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$root.$t('ui.yes'),
        cancelButtonText: this.$root.$t('ui.no'),
        reverseButtons: true
      });
    },
    async showVersioningPrompt(fileName, currentVersion) {
      const versionArray = _.map(currentVersion.split('.'), subVersion => {
        return parseInt(subVersion)
      });
      let html = this.$t('dragAndDropContainer.versioningPrompt.html.message', {fileName, currentVersion});
      html += "<br/><br/>";
      html += "<label for='version-input-container'>";
      html += this.$t('dragAndDropContainer.versioningPrompt.html.labelVersion');
      html += "</label>";
      html += "<div id='version-input-container' style='display: grid; grid-template-columns: 1fr auto 1fr auto 1fr; grid-column-gap: 0.5rem;'>";
      html += "   <input id='version-input0' value='" + versionArray[0] + "' style='font-size: initial; text-align: center; margin: 0px;' type='number' class='swal2-input'>";
      html += "   <div style='align-self: end; font-size: 3rem; margin-bottom: 0.5rem; color: gray'>.</div>";
      html += "   <input id='version-input1' value='" + versionArray[1] + "' style='font-size: initial; text-align: center;  margin: 0px;'' type='number' class='swal2-input'>";
      html += "   <div style='align-self: end; font-size: 3rem; margin-bottom: 0.5rem; color: gray'>.</div>";
      html += "   <input id='version-input2' value='" + (versionArray[2] + 1) + "' style='font-size: initial; text-align: center;  margin: 0px;'' type='number' class='swal2-input'>";
      html += "</div>";
      html += "<br/>";
      html += "<label for='description-input'>";
      html += this.$t('dragAndDropContainer.versioningPrompt.html.labelDescription');
      html += "</label>";
      html += "<textarea id='description-input' style='height: 7rem; margin: 0px; font-size: initial;' class='swal2-input'>";

      const swal = this.$swal({
        title: this.$t('dragAndDropContainer.versioningPrompt.title'),
        html,
        icon: 'warning',
        confirmButtonText: this.$root.$t('ui.save'),
        cancelButtonText: this.$root.$t('ui.cancel'),
        showCancelButton: true,
        allowOutsideClick: false,
        reverseButtons: true,
        preConfirm: () => {
          const retVal = {
            major: document.getElementById('version-input0').value,
            technical: document.getElementById('version-input1').value,
            editorial: document.getElementById('version-input2').value,
            description: document.getElementById('description-input').value
          };
          // No version entered
          if ((!retVal.major && retVal.major !== 0) || (!retVal.technical && retVal.technical !== 0) || (!retVal.editorial && retVal.editorial !== 0)) {
            swal.showValidationMessage(
                this.$t('dragAndDropContainer.versioningPrompt.versionNeededError')
            )
          }
          // 2.1.5 becomes 2.1.4
          if (parseInt(retVal.editorial) < versionArray[2] && parseInt(retVal.technical) <= versionArray[1] && parseInt(retVal.major) <= versionArray[0]) {
            swal.showValidationMessage(
                this.$t('dragAndDropContainer.versioningPrompt.versionLowerThanPrevious', {currentVersion})
            )
          }
          // 2.1.0 becomes 2.0.9
          if (parseInt(retVal.technical) < versionArray[1] && parseInt(retVal.major) <= versionArray[0]) {
            swal.showValidationMessage(
                this.$t('dragAndDropContainer.versioningPrompt.versionLowerThanPrevious', {currentVersion})
            )
          }
          // 2.0.0 becomes 1.9.9
          if (parseInt(retVal.major) < versionArray[0]) {
            swal.showValidationMessage(
                this.$t('dragAndDropContainer.versioningPrompt.versionLowerThanPrevious', {currentVersion})
            )
          }

          // Version number the same?
          if (`${retVal.major}.${retVal.technical}.${retVal.editorial}` === currentVersion) {
            swal.showValidationMessage(
                this.$t('dragAndDropContainer.versioningPrompt.versionNumberTheSame', {currentVersion})
            )
          }

          return retVal;
        },
      });
      return swal;
    },
    getFileRef(path) {
      return firebase
          .storage()
          .ref(path);
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    fileDeleted: function (fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      this.fileRecordsForUpload.splice(i, 1);
    },
    async uploadFiles(fileManagement = true) {
      const uploadPromises = [];
      for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
        const file = this.fileRecordsForUpload[i];
        uploadPromises.push(this.uploadFile(file, fileManagement));
      }
      try {
        // This is an array containing all file metadata that have been uploaded in the promise
        const result = await Promise.all(uploadPromises).catch(error => {
          if (error.code === 'NO_OVERWRITE' || error.code === 'NO_VERSION') {
            if (this.uploadedFiles && this.uploadedFiles.length) {
              this.getPreUploadedFilePreviews(this.uploadedFiles);
              this.swalToastInfo(this.$t('dragAndDropContainer.restoredLinkedFile'))
            } else {
              this.fileRecordsForUpload = [];
              this.fileRecords = [];
              this.swalToastInfo(this.$t('dragAndDropContainer.removedFile'))
            }
          } else {
            console.error(error);
          }
          return { error: error.code };
        });
        if (!result) {
          return false;
        } else if (result.error) {
          return result;
        }
        // If the user does not wish to overwrite al file: the promise returns undefined => so filter those out when returning
        const selectedFiles = _.filter(result, file => {
          return file !== undefined
        });
        // The result is all we uploaded: however if there's an item picked from the library: there's nothing uploaded
        // So return the selected items too.
        this.fileRecords.forEach(fileFromLibrary => {
          // If the file record contains a metadata property: it's already uploaded before
          if (fileFromLibrary.metadata) {
            selectedFiles.push(fileFromLibrary);
          }
        });
        if (this.showSuccessToast && selectedFiles.length) {
          this.swalToastSuccess(this.$tc('dragAndDropContainer.uploadSuccess', this.fileRecordsForUpload.length));
        }
        this.fileRecordsForUpload = [];
        return Promise.resolve(selectedFiles);
      } catch (e) {
        if (this.showFailedToast) {
          this.swalError(this.$tc('dragAndDropContainer.uploadFailed', this.fileRecordsForUpload.length));
        }
        this.fileRecordsForUpload = [];
        return Promise.reject(e);
      }
    },
    async uploadFile(file, fileManagement = true) {
      try {
        const storageRef = this.getFileRef(`${this.path}/${file.file.name}`);
        // With images the drag-drop-plugin picks a random color from the image
        // We don't want our file library using random colors: so all images get the same color.
        const colorToSave = file.type.indexOf('image') > -1 ? 'rgb(255, 255, 255)' : file.color;
        let metadata = fileManagement ? await this.getMetaDataOfFile(storageRef) : undefined;
        // init the metadata
        if (metadata === undefined) {
          metadata = {
            contentType: file.type,
            metageneration: 0,
            customMetadata: {
              color: colorToSave,
              createdByUid: this.getMe.uid,
              createdAt: new Date().toISOString(),
              version: '1.0.0'
            }
          }
        } else {
          metadata.customMetadata.color = colorToSave;

          this.$store.commit("setLoading", false);
          const overwriteFile = await this.showFileExistsPrompt(metadata.name);

          if (!overwriteFile.value) {
            return Promise.reject({code: 'NO_OVERWRITE', file});
          } else {
            const oldVersion = _.cloneDeep(metadata.customMetadata.version);
            const res = await this.showVersioningPrompt(metadata.name, oldVersion);
            if (!res.value) {
              return Promise.reject({code: 'NO_VERSION', file});
            }
            this.$store.commit("setLoading", true);
            metadata.customMetadata.version = `${res.value.major}.${res.value.technical}.${res.value.editorial}`;
            if (res.value.description) {
              metadata.customMetadata.versionDescription = res.value.description;
            }
            const oldPath = `${this.path}/${file.file.name}`;
            const newPath = `${this.path}/_${file.file.name}/${oldVersion}_${file.file.name}`;
            await this.moveFile(oldPath, newPath)
          }
        }
        // add the updating user to the metadata
        metadata.customMetadata.updatedByUid = this.getMe.uid;
        metadata.customMetadata.updatedAt = new Date().toISOString();
        metadata.metageneration++;

        const res = await storageRef
            .put(file.file, metadata).catch(e => {
              return Promise.reject(e);
            });
        return Promise.resolve(res);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async moveFile(oldPath, newPath) {
      const oldFileRef = this.getFileRef(oldPath);
      const oldFileMetaData = await this.getMetaDataOfFile(oldFileRef);
      const oldFileUrl = await oldFileRef.getDownloadURL();

      // There is no way to move files with firebase storage => so the only way is to download it and upload it again
      const fileBlob = await axios({url: oldFileUrl, method: 'GET', responseType: 'blob'})

      const newFileRef = this.getFileRef(newPath);

      return newFileRef
          .put(fileBlob.data, oldFileMetaData).catch(e => {
            return Promise.reject(e);
          });
    },
    async getMetaDataOfFile(ref) {
      const metadata = await ref.getMetadata().catch(e => {
        if (e.code === 'storage/object-not-found') {
          return undefined;
        } else {
          throw e;
        }
      });
      return metadata;
    },
    async getPreUploadedFilePreviews(fileArray) {
      // Process each file record to show in the drag and drop as pre-selected
      const preUploadedFiles = [];
      for (let i = 0; i < fileArray.length; i++) {
        const storageRef = this.getFileRef(fileArray[i].fullPath);
        const metadata = await storageRef.getMetadata();
        let downloadUrl = null;
        // Only download the file if it's not an pdf: otherwise there's nothing to preview
        if (metadata.type !== 'application/pdf') {
          downloadUrl = await storageRef.getDownloadURL();
        }
        preUploadedFiles.push(
            {
              "name": metadata.name,
              "size": metadata.size,
              "type": metadata.contentType,
              "ext": _.last(metadata.name.split('.')),
              "url": downloadUrl,
              "videoThumbnail": downloadUrl,
              "imageThumbnail": downloadUrl,
              "metadata": metadata
            },
        )
      }
      this.fileRecords = preUploadedFiles;
      this.fileRecordsForUpload = [];
    }
  },
  created() {
    this.getPreUploadedFilePreviews(this.uploadedFiles)
  },
  watch: {
    uploadedFiles: {
      deep: true,
      handler(newFileArray) {
        this.getPreUploadedFilePreviews(newFileArray)
      }
    },
    fileRecords: {
      deep: true,
      handler:function() {
        this.$emit('filesChanged', this.fileRecords);
      }
    }
  }
}
</script>

<style scoped>
.file-button {
  white-space: normal;
  height: 100%;
  width: 100%;
}

</style>
<style>
.file-preview-wrapper > .has-error > .file-preview > .file-error-wrapper > .vue-file-agent, .file-error-wrapper, .file-error-message {
  color: red !important;
  background-color: #dcdcdf !important;
}

</style>