<template>
  <div class="track-and-trace-content-wrapper">
    <div class="track-and-trace-content" :key="'job_' + index"
         v-for="(jobSet, index) in (orderStatus ? orderStatus : 1)">
      <div class="row m-0 track-and-trace-custom-properties">

        <div v-if="editing || getTrackAndTraceConfig.customPropertyKey0" class="col-12 custom-property-column"
             :class="'col-md-' + getAmountOfColumns">
          <template v-if="editing">
            <label for="customPropertyKey0">{{ $t('trackAndTrace.content.customPropertyKey') }}</label>
            <select name="" class=form-control v-model="customPropertyKey0" id="customPropertyKey0">
              <option :value="null">{{ $t('trackAndTrace.content.dontDisplayProperty') }}</option>
              <option v-for="key in getPossibleProductKeys" :value="key" :key="key">{{
                  translateCustomProperty(key)
                }}
              </option>
            </select>
            <label for="customPropertyLabel0">{{ $t('trackAndTrace.content.customPropertyLabel') }}</label>
            <input type="text" id="customPropertyLabel0" class="form-control" v-model="customPropertyLabel0">
          </template>
          <template v-else>
            <div class="suborder-label">{{ customPropertyLabel0 }}</div>
            <div class="suborder-value">
              {{ getPropertyValue(jobSet, customPropertyKey0) }}
            </div>
          </template>
        </div>
        <div v-if="editing || getTrackAndTraceConfig.customPropertyKey1" class="col-12  custom-property-column"
             :class="'col-md-' + getAmountOfColumns">
          <template v-if="editing">
            <label for="customPropertyKey1">{{ $t('trackAndTrace.content.customPropertyKey') }}</label>
            <select name="" class=form-control v-model="customPropertyKey1" id="customPropertyKey1">
              <option :value="null">{{ $t('trackAndTrace.content.dontDisplayProperty') }}</option>
              <option v-for="key in getPossibleProductKeys" :value="key" :key="key">{{
                  translateCustomProperty(key)
                }}
              </option>
            </select>
            <label for="customPropertyLabel1">{{ $t('trackAndTrace.content.customPropertyLabel') }}</label>
            <input type="text" id="customPropertyLabel1" class="form-control" v-model="customPropertyLabel1">
          </template>
          <template v-else>
            <div class="suborder-label">{{ customPropertyLabel1 }}</div>
            <div class="suborder-value">
              {{ getPropertyValue(jobSet, customPropertyKey1) }}
            </div>
          </template>
        </div>
        <div v-if="editing || getTrackAndTraceConfig.customPropertyKey2" class="col-12  custom-property-column"
             :class="'col-md-' + getAmountOfColumns">
          <template v-if="editing">
            <label for="customPropertyKey2">{{ $t('trackAndTrace.content.customPropertyKey') }}</label>
            <select name="" class=form-control v-model="customPropertyKey2" id="customPropertyKey2">
              <option :value="null">{{ $t('trackAndTrace.content.dontDisplayProperty') }}</option>
              <option v-for="key in getPossibleProductKeys" :value="key" :key="key">{{
                  translateCustomProperty(key)
                }}
              </option>
            </select>
            <label for="customPropertyLabel2">{{ $t('trackAndTrace.content.customPropertyLabel') }}</label>
            <input type="text" id="customPropertyLabel2" class="form-control" v-model="customPropertyLabel2">
          </template>
          <template v-else>
            <div class="suborder-label">{{ customPropertyLabel2 }}</div>
            <div class="suborder-value">
              {{ getPropertyValue(jobSet, customPropertyKey2) }}
            </div>
          </template>
        </div>
      </div>
      <TrackAndTraceColumns @columnChanged="handleColumnChange($event)" @extraDurationChanged="setExtraDuration($event)" :jobs="jobSet" ref="trackAndTraceColumns"
                            :editing="editing"/>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TrackAndTraceColumns from "@/components/trackAndTrace/TrackAndTraceColumns.vue";

export default {
  name: "TrackAndTraceContent",
  components: {TrackAndTraceColumns},
  props: ['editing', 'orderStatus'],
  data() {
    return {
      customPropertyLabel0: null,
      customPropertyLabel1: null,
      customPropertyLabel2: null,
      customPropertyKey0: null,
      customPropertyKey1: null,
      customPropertyKey2: null,
      activitiesColumn0: {label: null, activities: []},
      activitiesColumn1: {label: null, activities: []},
      activitiesColumn2: {label: null, activities: []},
      activitiesColumn3: {label: null, activities: []},
      extraDuration: 0
    }
  },
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    ...mapGetters("jobs", ["getJobs"]),
    ...mapGetters('company', ['getConfig']),
    ...mapGetters("users", ["getClaims", "getMe"]),
    getPossibleProductKeys() {
      const dynamicOptions = ['DYNAMIC_TIME_LEFT'];


      if (this.getConfig && this.getConfig.trackAndTrace && this.getConfig.trackAndTrace.customProperties) {
        return _.concat(this.getConfig.trackAndTrace.customProperties, dynamicOptions)
      } else {
        const uniqueKeys = _.uniq(_.flattenDeep(_.map(this.getJobs, job => {
          return _.keys(job)
        })));
        return _.concat(_.filter(uniqueKeys, key => ['created_at', 'created_by', 'updated_at', 'updated_by', 'id', 'companyId'].indexOf(key) === -1), dynamicOptions)
      }
    },
    getAmountOfColumns() {
      if (this.editing) {
        return 4;
      }
      const amountOfEnabledKeys = _.filter([!!this.customPropertyKey0, !!this.customPropertyKey1, !!this.customPropertyKey2], key => {
        return !!key
      }).length;
      return 12 / amountOfEnabledKeys;
    },

  },
  methods: {
    translateCustomProperty(value) {
      if (!value) return ''
      else if (value === 'DYNAMIC_TIME_LEFT') {
        return this.$t('trackAndTrace.content.dynamicProperties.timeLeft');
      } else {
        return value;
      }
    },
    setExtraDuration(newDuration) {
      this.extraDuration = newDuration;
    },
    handleColumnChange(columnChange) {
      this[`activitiesColumn${columnChange.columnIndex}`] = columnChange.data;
    },
    getPropertyValue(jobSet, property) {
      if (property === 'DYNAMIC_TIME_LEFT') {
        let daysLeft = _.sumBy(jobSet, job => {
          if (job.status === 'finished') {
            return 0;
          } else {
            let amountOfDaysLeft = 1;
            for (let i = 0; i < 4; i++) {
              const activitySettings = _.find(this.getTrackAndTraceConfig[`activitiesColumn${i}`].activities, {'value': job.activity});
              if (activitySettings) {
                if (activitySettings.workingDays !== null) {
                  amountOfDaysLeft = parseInt(activitySettings.workingDays);
                }
              }
            }
            return amountOfDaysLeft;
          }
        });

        if (daysLeft && this.getTrackAndTraceConfig.extraDuration) {
          // Add extra duration when there are still days left
          daysLeft += parseInt(this.getTrackAndTraceConfig.extraDuration);
        }
        return this.$tc('trackAndTrace.content.days', daysLeft);
      } else {
        return jobSet && jobSet[0] ? jobSet[0][property] : '';
      }
    }
  },
  created() {
    this.customPropertyLabel0 = this.getTrackAndTraceConfig.customPropertyLabel0;
    this.customPropertyKey0 = this.getTrackAndTraceConfig.customPropertyKey0;
    this.customPropertyLabel1 = this.getTrackAndTraceConfig.customPropertyLabel1;
    this.customPropertyKey1 = this.getTrackAndTraceConfig.customPropertyKey1;
    this.customPropertyLabel2 = this.getTrackAndTraceConfig.customPropertyLabel2;
    this.customPropertyKey2 = this.getTrackAndTraceConfig.customPropertyKey2;
    this.extraDuration = this.getTrackAndTraceConfig.extraDuration ? this.getTrackAndTraceConfig.extraDuration : 0;
    if (this.editing) {
      let where = [
        ['companyId', '==', this.getClaims.companyId],
        ['plannedDate', '>=', moment().subtract(5, 'day').toDate()]
      ];
      this.$store.dispatch('jobs/fetchAndAdd', {where});
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

$informationHeight: 68px;


@media only screen and (max-width: 900px) {
  .track-and-trace-content {
    margin-left: 8px !important;
    margin-right: 8px !important;
    padding: 8px 8px !important;
  }
}

.track-and-trace-content {
  margin-left: 48px;
  margin-right: 48px;
  padding: 12px 32px;

  background: $walterWhite;
  border: 1px solid $grey;
  border-radius: 8px;
}

.track-and-trace-content:not(:last-child) {
  margin-bottom: 34px;
}


.custom-property-column {
  text-align: center;

}

.custom-property-column:first-child {
  text-align: left !important;
}

.custom-property-column:last-child {
  text-align: right;
}


// To align all text again on small displays 768px = bootstrap md. And on col-md-12 all should be left
@media (max-width: 768px) {
  .custom-property-column {
    text-align: left !important;
  }
}

.suborder-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $besWelBlack;
}

.suborder-value {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $besWelBlack;
}

#customPropertyKey0, #customPropertyKey1, #customPropertyKey2 {
  margin-bottom: 12px;
}
</style>