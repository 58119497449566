<template>
  <div class="fileVersionWrapper">
    <div v-if="getFilesBySearch.length">
      <h5>{{ $t('fileVersionSelector.title', {fileName: currentVersion.name}) }}</h5>
      <div class="version-table">
        <data-tables
            class="first-checkbox"
            :tableClass="tableClass"
            :columns="getColumns"
            :rows="getFilesBySearch"
            tableID="instructions-version-dt"
            routerLinkClass="dt-router-link"
            :tableInitOptions="tableInitOptions"
            :customFilters="[]"
            @rowSelected="selectVersion($event)"
        />
      </div>
    </div>
    <div class="footer">
      <div class="text-right">
        <button data-toggle="modal"
                data-target="#fileVersionSelectorModal"
                class="btn btn-cancel">
          {{ $t('ui.cancel') }}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "FileVersionSelector",
  data() {
    return {
      fileHistory: [],
      currentlySelectedFile: null,
      currentVersion: null,
      searchQuery: '',
      tableClass: 'table-striped table-bordered responsive nowrap',
    }
  },
  methods: {
    selectVersion(event) {
      this.$emit('fileSelected', {file: event});
      $('#fileVersionSelectorModal').attr('fileSelected', true).modal('hide');
    },
    getFileVersions(file, currentlySelectedFile) {
      this.currentVersion = file;
      this.currentlySelectedFile = currentlySelectedFile;
      this.fileHistory = [file];
      // The version path is a folder which contains an underscore + the filename
      // For example file bitmap.bmp has its prior versions in folder _bitmap.bmp
      this.getFilesInPath(file.fullPath.replace(file.name, '_' + file.name));
    },
    async getFilesInPath(path) {
      this.$store.commit("setLoading", true);
      const storageRef = firebase
          .storage()
          .ref(path);
      const storageListings = await storageRef.listAll();
      for (let i = 0; i < storageListings.items.length; i++) {
        const metadata = await firebase.storage().ref(storageListings.items[i].fullPath).getMetadata();
        this.fileHistory.push(metadata);
      }
      this.$store.commit("setLoading", false);

    },
  },
  computed: {
    tableInitOptions() {
      const language = require(`../../locales/datatables.${this.$i18n.locale}.json`);
      return {
        order: [[0, 'desc']],
        language,
        buttons: [],
        drawCallback() {
          // Hide parts of the datatable
          $('#instructions-version-dt_length').remove();
          $('#instructions-version-dt_info').remove();
          $('#instructions-version-dt_paginate').width('100%')
        }
      };
    },
    getFilesBySearch() {
      return _.filter(this.fileHistory, file => {
        if (file.customMetadata.versionDescription) {
          return file.customMetadata.versionDescription.indexOf(this.searchQuery) > -1 || file.customMetadata.version.indexOf(this.searchQuery) > -1;
        } else {
          return file.customMetadata.version.indexOf(this.searchQuery) > -1;
        }
      });
    },
    getColumns() {
      let self = this;
      return [
        {
          data: null,
          name: self.$t('fileVersionSelector.version'),
          responsivePriority: 1,
          render(data) {
            let columnData = data.customMetadata.version;

            if (data.customMetadata.version === self.currentVersion.customMetadata.version) {
              columnData += '<div style=" padding: 0px 1rem; background: #d0ffbf; border-radius: 24px; display: inline-block; margin-left: 1rem;">' + self.$t('fileVersionSelector.currentVersion') + '</div>';
            }
            if (self.currentlySelectedFile && data.fullPath === self.currentlySelectedFile.fullPath) {
              columnData += '<div style=" padding: 0px 1rem; background: #ffe0bf; border-radius: 24px; display: inline-block; margin-left: 1rem;">' + self.$t('fileVersionSelector.currentlySelected') + '</div>';
            }
            return columnData;
          }
        },
        {
          data: null,
          name: self.$t('fileVersionSelector.description'),
          responsivePriority: 1,
          orderable: false,
          render(data) {
            return data.customMetadata.versionDescription ? data.customMetadata.versionDescription : '-';
          }
        },
        {
          data: null,
          name: '',
          responsivePriority: 1,
          orderable: false,
          render(data) {
            return '<button class="btn btn-primary get-data" style="width: 100%;">' + self.$t('fileVersionSelector.useThisVersionButton') + '</button>';
          }
        }]
    },
  },
}
</script>

<style scoped>

.fileVersionWrapper {
  padding: 2rem
}

.version-table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

</style>

<style>
#instructions-version-dt {
  width: 100% !important;
}
</style>