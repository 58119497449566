<template>
  <div class="track-and-trace-column" :editing="editing">
    <template v-if="editing">
      <label
          :for="'column-name-'+ columnIndex">{{
          $t('trackAndTrace.column.columnName', {column: columnIndex + 1})
        }}</label>
      <div class="max-length-input">
        <input :maxlength="50" type="text" :id="'column-name-'+ columnIndex" class="form-control column-name"
               v-model="listName"
               @keyup="$emit('labelChanged', listName)"
               :placeholder="getPlaceHolder">
        <div class="max-length">{{ listName.length }}/50</div>
      </div>

      <label class="activity-in-column-label"
             :for="'column-drag-and-drop-'+ columnIndex">{{ $t('trackAndTrace.column.columnActivities') }}:</label>

      <draggable class="list-group"
                 :id="'column-drag-and-drop-'+ columnIndex"
                 :list="activitiesInList"
                 draggable=".item"
                 @change="$emit('listChanged', activitiesInList)"
                 group="track-and-trace-column">
        <div
            class="list-group-item item"
            v-for="activity in activitiesInList"
            :key="activity.value"
        >
          <div class="column-activity-header">{{ $t('trackAndTrace.column.activities.raw') }}{{ activity.value }}</div>
          <label
              :for="'column-activity-label-'+ activity.value">{{
              $t('trackAndTrace.column.activities.displayName')
            }}</label>
          <div class="max-length-input">
            <input :maxlength="50" type="text" :id="'column-activity-label-'+ activity.value" class="form-control"
                   v-model="activity.label"
                   :placeholder="$t('trackAndTrace.column.placeholders.displayName')">
            <div class="max-length">{{ activity.label.length }}/50</div>
          </div>

          <label
              :for="'column-activity-working-days-'+ activity.value">{{
              $t('trackAndTrace.column.activities.duration')
            }}</label>
          <div class="input-group">
            <input min="0" type="number" :id="'column-activity-working-days-'+ activity.value" class="form-control"
                   v-model="activity.workingDays"
                   :placeholder="$t('trackAndTrace.column.placeholders.duration')">
            <div class="input-group-append">
              <span class="input-group-text duration"
                    id="basic-addon2">{{ $tc('trackAndTrace.column.days', activity.workingDays) }}</span>
            </div>
          </div>

          <label
              :for="'column-activity-extra-info-'+ activity.value">{{
              $t('trackAndTrace.column.activities.extraInfo')
            }}</label>
          <div class="input-group">
            <textarea min="0" :maxlength="350" max="350" type="number"
                      :id="'column-activity-extra-info-'+ activity.value" class="form-control"
                      v-model="activity.extraInfo"
                      :placeholder="$t('trackAndTrace.column.placeholders.extraInfo')"/>
            <div class="max-length">{{ activity.extraInfo ? activity.extraInfo.length : 0 }}/350</div>
          </div>
        </div>

        <div
            slot="header"
            class="btn-group list-group-item"
            role="group"
            aria-label="Basic example"
            v-if="!activitiesInList || !activitiesInList.length"
        >
          {{ $t('trackAndTrace.column.placeholders.duration') }}
        </div>
      </draggable>
    </template>
    <template v-else>

      <div class="track-and-trace-column-header">
        <div class="track-and-trace-column-checkmark" v-if="allJobsFinished">
          <i class="fas fa-check"></i>
        </div>
        <div class="track-and-trace-column-label">
          {{ getTrackAndTraceConfig['activitiesColumn' + columnIndex].label }}
        </div>
      </div>
      <div :style="{ 'grid-template-columns': getTemplateColumnClass }" class="track-and-trace-activities"
           :id="'track-and-trace-activities-column-' + columnIndex">
        <div class="track-and-trace-activity" :key="activity.value"
             v-for="activity in getTrackAndTraceConfig['activitiesColumn' + columnIndex].activities">
          <div class="track-and-trace-activity-label">
            <div>{{ activity.label }}</div>
            <div v-if="activity.extraInfo" class="extra-info">
              <popper
                  trigger="clickToOpen"
                  root-class="tt-popper"
                  :visible-arrow="true"
                  :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0,10px' } }
              }">
                <div class="popper">
                  {{ activity.extraInfo }}
                </div>

                <i slot="reference" class="far fa-info-circle"></i>
              </popper>
            </div>
          </div>
          <template v-if="getJobOfActivity(activity.value)">
            <div
                :class="getJobOfActivity(activity.value).status === 'finished' ?  'track-and-trace-activity-status-finished' : (currentActivity === activity.value ? 'track-and-trace-activity-status-busy' : 'track-and-trace-activity-status')">
            </div>
            <div class="track-and-trace-activity-finished-date">
              <template v-if="activityToShowDate === activity.value">
                {{ $t('trackAndTrace.column.lastActivity') }}<br/>
                {{ getJobOfActivity(activity.value).finishedDate | formatDate }}
              </template>
              <template v-else-if="currentActivity === activity.value">
                {{ $t('trackAndTrace.column.currentActivity') }}
              </template>
            </div>
          </template>
          <template v-else>
            {{ $t('trackAndTrace.column.jobNotFound') }}
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTraceColumn",
  props: ['editing', 'columnIndex', 'jobs', 'activityToShowDate', 'currentActivity'],
  data() {
    return {
      activitiesInList: [],
      listName: '',
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      return moment(value).format('DD-MM-YYYY HH:mm')
    }
  },
  components: {draggable},
  computed: {
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
    getTemplateColumnClass() {
      let templateColumnClass = '';
      // for (let i = 0; i < this.getTrackAndTraceConfig['activitiesColumn' + this.columnIndex].activities.length; i++) {
      //   templateColumnClass += 'minmax(auto, 1fr) ';
      // }
      // console.log("templateColumnClass", templateColumnClass)
      return templateColumnClass;
    },
    getPlaceHolder() {
      if (this.columnIndex === 0) {
        return this.$t('trackAndTrace.column.placeholders.columnName_0')
      } else if (this.columnIndex === 1) {
        return this.$t('trackAndTrace.column.placeholders.columnName_1')
      } else if (this.columnIndex === 2) {
        return this.$t('trackAndTrace.column.placeholders.columnName_2')
      } else {
        return this.$t('trackAndTrace.column.placeholders.columnName_3')
      }
    },
    allJobsFinished() {
      return _.filter(this.jobs, {'status': 'finished'}).length === this.jobs.length;
    }
  },
  methods: {
    getJobOfActivity(activity) {
      const foundJob = _.find(this.jobs, {activity});
      if (foundJob) {
        return foundJob;
      } else {
        return {'status': 'finished'}
      }
    },
  },
  created() {
    if (this.editing) {
      const currentConfigOfColumn = this.getTrackAndTraceConfig['activitiesColumn' + this.columnIndex];
      if (currentConfigOfColumn) {
        this.activitiesInList = currentConfigOfColumn.activities ? currentConfigOfColumn.activities : [];
        this.listName = currentConfigOfColumn.label ? currentConfigOfColumn.label : '';
      }
    }

  },
  watch: {
    'activitiesInList': {
      deep: true,
      handler:function() {
        this.$emit('subActivityChanged', {label: this.listName, activities: this.activitiesInList})
      }
    },
    'listName': {
      deep: true,
      handler:function() {
        this.$emit('subActivityChanged', {label: this.listName, activities: this.activitiesInList})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/MotivateColors";

.extra-info {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .track-and-trace-column {
    margin-top: 1rem !important;
    border-radius: 8px !important;
    padding: 8px 8px !important;
  }
}

.track-and-trace-column {
  margin-top: 2rem;
  border: 1px solid $cultured;
  border-radius: 8px;
  padding: 20px 16px;
  height: 100%;
}

.track-and-trace-column:last-child {
  margin-bottom: 1rem;
}

.list-group {
  margin-top: 1rem;
}

.column-activity-header {
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 6px;
}

.item {
  cursor: move;
  border-style: dashed;
}

.column-name {
  font-weight: 700;
  font-size: 1.25rem;
  color: $besWelBlack;
}

.activity-in-column-label {
  margin-top: 2rem;
  margin-bottom: 0px;
}

@media only screen and (max-width: 900px) {
  .track-and-trace-column-label {
    font-size: 1rem !important;
  }
}
.track-and-trace-column-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: #000000;
  width: -webkit-fill-available;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-and-trace-column-checkmark {
  background: $superSuccess;
  width: 20px;
  height: 20px;
  /* Neutral Color / 000 */
  border: 1.5px solid $walterWhite;
  border-radius: 24px;
  color: $walterWhite;
  padding-top: 0.1rem;
  font-size: 0.625rem;
  display: grid;
  justify-content: center;
  align-content: center;
}

.track-and-trace-column-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  justify-items: start;
  align-items: center;
}

.track-and-trace-activity-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $besWelBlack;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 5px;
}

.track-and-trace-activity-status {
  width: 100%;
  height: 6px;
  background: #CED4DA;
  border-radius: 4px;
  margin: 4px 0px;
}

.track-and-trace-activity-status-busy {
  @extend .track-and-trace-activity-status;
  background: #2A52BE;
}

.track-and-trace-activity-status-finished {
  @extend .track-and-trace-activity-status;
  background: #3EC27C;
}

.track-and-trace-activity-finished-date {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $besWelBlack;
}

.duration {
  margin-bottom: 12px;
  padding: 4px 12px;
  width: 100px;
  background-color: $softGrey;
  color: inherit;
  height: 49px;
}

.track-and-trace-activities {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}


.track-and-trace-activity {
  width: 100%;
  min-width: 80px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 14px 16px;
}


.track-and-trace-column[editing=true] {
  background: $softGrey;

  .list-group > .item {
    background: #dddddd;
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: move;
    border-style: dashed;
    border-color: $cadetGrey;
  }
}

.max-length {
  position: relative;
  top: -16px;
  right: 6px;
  width: 100%;
  text-align: right;
  font-size: 0.8rem;
  z-index: 999;
}

/deep/ .tt-popper > .popper {
  border-color: $cultured;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 10px 16px rgba(0, 0, 0, 0.06);
  padding: 0.4rem;

  .popper__arrow {
    border-color: $cultured transparent transparent transparent;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 10px 16px rgba(0, 0, 0, 0.06);

  }
}


</style>