<template>
  <div @click="openFile(file)"
       :key=file.id
       class="file"
       :style="getFileStyle(file.customMetadata)"
       data-toggle="modal"
       data-target="#fileBrowserModal">
  <div class="version-button" @click="showHistory($event, file)" v-if="file.customMetadata.version !== '1.0.0'">
    {{ $t('fileBrowser.chooseOlderVersionButton') }}
  </div>
  <div class="version-button-placeholder" v-else>&zwnj;</div>
  <div>
    <i class="file-icon fas" :class="getFontAwesomeIcon(file.contentType)"></i>
    <div class="version-container">
      <div :style="getVersionStyle(file.customMetadata)" class="version">v{{ file.customMetadata.version }}</div>
    </div>
  </div>
  <span class="file-name" :title="file.name">{{ file.name }} </span>
  </div>
</template>

<script>
import mimetypes from "font-awesome-filetypes/lib/mimetypes";

export default {
  name: "FileTile",
  props: ['file'],
  computed: {
    getFontAwesomeIcon: () => (mimetype) => {
      return mimetypes[mimetype];
    }
  },
  methods: {
    getFileStyle(metadata) {
      return {
        'background': '#FFFFFF',
        'color': metadata.color === 'rgb(255, 255, 255)' ? '#00C9FF' : metadata.color,
        'border': `1px solid ${metadata.color === 'rgb(255, 255, 255)' ? '#00C9FF' : metadata.color}`
      }
    },
    getVersionStyle(metadata) {
      return {
        'background': '#FFFFFF',
        'color': metadata.color === 'rgb(255, 255, 255)' ? '#00C9FF' : metadata.color,
        'border': `1px solid ${metadata.color === 'rgb(255, 255, 255)' ? '#00C9FF' : metadata.color}`
      }
    },
    openFile(file) {
      this.$emit('openFile', file);
    },
    showHistory($event, file) {
      this.$emit('showHistory', { $event, file });
    }
  }
}
</script>

<style scoped>

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-top: 1rem;
}

.history-button {
  text-align: right;
  font-size: 1.8rem;
  align-self: center;
}

.version {
  text-align: left;
  font-size: 1.3rem;
  -ms-flex-item-align: center;
  position: relative;
//border: 1px solid silver;
  border-radius: 24px;
  padding-left: 1rem;
  padding-right: 1rem;
//background: #979797;
//color: white;
  bottom: 1rem;
  width: fit-content;
  /* right: 1rem; */
  margin-left: 6rem;
}

.file-icon {
  font-size: 6rem;
}

.file {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 15rem;
  width: 15rem;
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.version-button {
  font-size: 1.3rem;
  -ms-flex-item-align: center;
  align-self: center;
  font-weight: bold;
  margin-bottom: 1rem;
  border: 1px solid;
  border-radius: 4px;
}

.version-button-placeholder {
  font-size: 1.3rem;
  -ms-flex-item-align: center;
  align-self: center;
  font-weight: bold;
  margin-bottom: 1rem;
  border: 0px solid;
  border-radius: 4px;
}

.version-container {
  position: fixed;
}

</style>