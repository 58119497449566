<template>
<div class="track-and-trace-activities-pool">
  <label :for="'column-drag-and-drop-pool'">{{ $t('trackAndTrace.activitiesPool.availableActivities')}}</label>
  <draggable class="list-group"
             :id="activitiesToChoose.length <= 1 ? 'column-drag-and-drop-pool-1' : activitiesToChoose.length == 2 ?  'column-drag-and-drop-pool-2': 'column-drag-and-drop-pool'"
             :list="activitiesToChoose"
             :sort="true"
             draggable=".item"
             group="track-and-trace-column">
    <div
        class="list-group-item item"
        v-for="activity in activitiesToChoose"
        :key="activity.value"
    >
      {{ activity.label  }}
    </div>

    <div
        slot="header"
        class="btn-group list-group-item"
        role="group"
        aria-label="Basic example"
        v-if="!activitiesToChoose.length"
    >
      {{ $t('trackAndTrace.activitiesPool.dropToRemove')}}
    </div>
  </draggable>
</div>
</template>

<script>
import draggable from "vuedraggable";
import {mapGetters} from "vuex";

export default {
  name: "TrackAndTraceActivitiesPool",
  props: ['editing', 'amountOfColumns'],
  components: { draggable },
  data() {
    return {
      activitiesToChoose: [

      ]
    }
  },
  computed: {
    ...mapGetters("company", ["getConfig", "getActivities"]),
    ...mapGetters('trackAndTrace', ['getTrackAndTraceConfig']),
  },
  created() {
    if (this.editing) {
      const unusedActivities = _.filter(this.getActivities, activity => {
        for(let i = 0; i < this.amountOfColumns; i++) {
          const activityOfColumn = this.getTrackAndTraceConfig['activitiesColumn'+ i];
          if (activityOfColumn && _.find(activityOfColumn.activities, {'value': activity})) {
            return false;
          }
        }
        return true;
      });

      this.activitiesToChoose = _.map(unusedActivities, activity => {
        return {
          value: activity,
          label: activity,
          workingDays: 0
        }
      });
    }
  }
}
</script>

<style scoped>


#column-drag-and-drop-pool {
  display: grid !important;
  grid-template-columns: auto auto auto;
}

#column-drag-and-drop-pool-1 {
  display: grid !important;
  grid-template-columns: auto !important;
}
#column-drag-and-drop-pool-2 {
  display: grid !important;
  grid-template-columns: auto auto;
}

</style>